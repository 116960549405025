import { lazy } from 'react';
import { Navigate } from 'react-router';
import { getCache } from 'helpers/local-storage';
import Loadable from 'component/Loadable';
import MainLayout from 'layout/MainLayout';

const DashboardDefault = Loadable(lazy(() => import('views/Dashboard')));
const PaymentMethod = Loadable(lazy(() => import('views/PaymentMethod')));
const FormPaymentMethod = Loadable(lazy(() => import('views/PaymentMethod/form')));
const Advertisement = Loadable(lazy(() => import('views/Advertisement')));
const FormAdvertisement = Loadable(lazy(() => import('views/Advertisement/form')));
const Banner = Loadable(lazy(() => import('views/Banner')));
const FormBanner = Loadable(lazy(() => import('views/Banner/form')));
const MenuGroup = Loadable(lazy(() => import('views/Menu/Group')));
const FormMenuGroup = Loadable(lazy(() => import('views/Menu/Group/form')));
const MenuList = Loadable(lazy(() => import('views/Menu/List')));
const FormMenuList = Loadable(lazy(() => import('views/Menu/List/form')));
const Voting = Loadable(lazy(() => import('views/Voting')));
const FormVoting = Loadable(lazy(() => import('views/Voting/form')));
const VotingGuestStar = Loadable(lazy(() => import('views/Voting/GuestStar')));
const FormVotingGuestStar = Loadable(lazy(() => import('views/Voting/GuestStar/form')));
const SystemParam = Loadable(lazy(() => import('views/SystemParam')));
const FormSystemParam = Loadable(lazy(() => import('views/SystemParam/form')));
const PopupBanner = Loadable(lazy(() => import('views/PopupBanner')));
const FormPopupBanner = Loadable(lazy(() => import('views/PopupBanner/form')));
const Certificate = Loadable(lazy(() => import('views/Certificate')));
const FormPopupCertificate = Loadable(lazy(() => import('views/Certificate/form')));
const Guidebook = Loadable(lazy(() => import('views/Guidebook')));
const FormGuidebook = Loadable(lazy(() => import('views/Guidebook/form')));
const UiUpdate = Loadable(lazy(() => import('views/UiUpdate')));
const FormUiUpdate = Loadable(lazy(() => import('views/UiUpdate/form')));

// ==============================|| MAIN ROUTES ||============================== //

const accessToken = getCache('access_token');
const email = getCache('email');

const renderElement = () => {
  if (accessToken) {
    return <MainLayout />;
  } else {
    return <Navigate to="/login" />;
  }
};

const renderPages = () => {
  if (email == 'humas@uimobile.net') {
    return [
      { path: 'dashboard', element: <DashboardDefault /> },
      {
        path: 'banner',
        children: [
          { index: true, element: <Banner /> },
          { path: ':type/:id?', element: <FormBanner /> }
        ]
      },
      {
        path: 'popup-banner',
        children: [
          { index: true, element: <PopupBanner /> },
          { path: ':type/:id?', element: <FormPopupBanner /> }
        ]
      },
      {
        path: 'ui-update',
        children: [
          { index: true, element: <UiUpdate /> },
          { path: ':type/:id?', element: <FormUiUpdate /> }
        ]
      }
    ];
  } else {
    return [
      { path: 'dashboard', element: <DashboardDefault /> },
      {
        path: 'payment-method',
        children: [
          { index: true, element: <PaymentMethod /> },
          { path: ':type/:id?', element: <FormPaymentMethod /> }
        ]
      },
      {
        path: 'advertisement',
        children: [
          { index: true, element: <Advertisement /> },
          { path: ':type/:id?', element: <FormAdvertisement /> }
        ]
      },
      {
        path: 'banner',
        children: [
          { index: true, element: <Banner /> },
          { path: ':type/:id?', element: <FormBanner /> }
        ]
      },
      {
        path: 'menu',
        children: [
          {
            path: 'group',
            children: [
              { index: true, element: <MenuGroup /> },
              { path: ':type/:id?', element: <FormMenuGroup /> }
            ]
          },
          {
            path: 'list',
            children: [
              { index: true, element: <MenuList /> },
              { path: ':type/:id?', element: <FormMenuList /> }
            ]
          }
        ]
      },
      {
        path: 'voting',
        children: [
          {
            children: [
              { index: true, element: <Voting /> },
              { path: ':type/:id?', element: <FormVoting /> }
            ]
          },
          {
            path: 'guest-star',
            children: [
              { path: ':id_voting', element: <VotingGuestStar /> },
              { path: ':id_voting/:type/:id?', element: <FormVotingGuestStar /> }
            ]
          }
        ]
      },
      {
        path: 'system-param',
        children: [
          { index: true, element: <SystemParam /> },
          { path: ':type/:id?', element: <FormSystemParam /> }
        ]
      },
      {
        path: 'popup-banner',
        children: [
          { index: true, element: <PopupBanner /> },
          { path: ':type/:id?', element: <FormPopupBanner /> }
        ]
      },
      {
        path: 'certificates',
        children: [
          { index: true, element: <Certificate /> },
          { path: ':type/:id?', element: <FormPopupCertificate /> }
        ]
      },
      {
        path: 'guidebooks',
        children: [
          { index: true, element: <Guidebook /> },
          { path: ':type/:id?', element: <FormGuidebook /> }
        ]
      },
      {
        path: 'ui-update',
        children: [
          { index: true, element: <UiUpdate /> },
          { path: ':type/:id?', element: <FormUiUpdate /> }
        ]
      }
    ];
  }
};

const MainRoutes = {
  path: '/',
  element: renderElement(),
  children: renderPages()
};

export default MainRoutes;
