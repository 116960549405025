import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import AdsClickOutlinedIcon from '@mui/icons-material/AdsClickOutlined';
import PanoramaOutlinedIcon from '@mui/icons-material/PanoramaOutlined';
import AppSettingsAltOutlinedIcon from '@mui/icons-material/AppSettingsAltOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import HowToVoteOutlinedIcon from '@mui/icons-material/HowToVoteOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import { getCache } from 'helpers/local-storage';

const email = getCache('email');

const renderMenuItems = () => {
  if (email == 'humas@uimobile.net') {
    return [
      {
        id: 'navigation',
        type: 'group',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            icon: HomeOutlinedIcon,
            url: '/dashboard'
          },
          {
            id: 'banner',
            title: 'Banner',
            type: 'item',
            icon: PanoramaOutlinedIcon,
            url: '/banner'
          },
          {
            id: 'popup-banner',
            title: 'Popup Banner',
            type: 'item',
            icon: CollectionsOutlinedIcon,
            url: '/popup-banner'
          },
          {
            id: 'ui-update',
            title: 'UI Update',
            type: 'item',
            icon: NewspaperOutlinedIcon,
            url: '/ui-update'
          }
        ]
      }
    ];
  } else {
    return [
      {
        id: 'navigation',
        type: 'group',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            icon: HomeOutlinedIcon,
            url: '/dashboard'
          },
          {
            id: 'payment-method',
            title: 'Metode Pembayaran',
            type: 'item',
            icon: PaymentOutlinedIcon,
            url: '/payment-method'
          },
          {
            id: 'advertisement',
            title: 'Advertisement',
            type: 'item',
            icon: AdsClickOutlinedIcon,
            url: '/advertisement'
          },
          {
            id: 'banner',
            title: 'Banner',
            type: 'item',
            icon: PanoramaOutlinedIcon,
            url: '/banner'
          },
          {
            id: 'popup-banner',
            title: 'Popup Banner',
            type: 'item',
            icon: CollectionsOutlinedIcon,
            url: '/popup-banner'
          },
          {
            id: 'ui-update',
            title: 'UI Update',
            type: 'item',
            icon: NewspaperOutlinedIcon,
            url: '/ui-update'
          },
          {
            id: 'menu',
            title: 'Menu',
            type: 'collapse',
            icon: WidgetsOutlinedIcon,
            children: [
              {
                id: 'menu-group',
                title: 'Grup Menu',
                type: 'item',
                url: '/menu/group'
              },
              {
                id: 'menu-list',
                title: 'List Menu',
                type: 'item',
                url: '/menu/list'
              }
            ]
          },
          {
            id: 'voting',
            title: 'Voting',
            type: 'item',
            icon: HowToVoteOutlinedIcon,
            url: '/voting'
          },
          {
            id: 'system-param',
            title: 'Sistem Param',
            type: 'item',
            icon: AppSettingsAltOutlinedIcon,
            url: '/system-param'
          },

          {
            id: 'certificates',
            title: 'Sertifikat',
            type: 'item',
            icon: CardMembershipIcon,
            url: '/certificates'
          },
          {
            id: 'guidebooks',
            title: 'Buku Panduan',
            type: 'item',
            icon: MenuBookIcon,
            url: '/guidebooks'
          }
        ]
      }
    ];
  }
};

export default {
  items: renderMenuItems()
};
